/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
import { Circle, Rectangle, TextStyle, Sprite as _Sprite, Text as _Text, Texture as _Texture } from 'pixi.js';
import { config } from '../../config';
import { ResourceController } from '../ResourceLoader';

export namespace core {
    /**
     * Extended {@link core.Text} with a banch of new methods.
     */
    export class Text extends _Text {
        public _copyable = false;
        public onStateUpdate?: () => void;

        private origTextWithOptions?: string;
        // ! TODO MAX Y SCALE
        private origFontSize?: string;

        constructor(text: string | number, style?: any | TextStyle, canvas?: HTMLCanvasElement | undefined) {
            super(text.toString(), style, canvas);

            this.resolution = config.textResolution;
        }

        /**
         * Set the width at which text will wrap
         * @param wrap width of your text field
         * @returns instance of {@link core.Text}
         */
        setWordWrap = (wrap: number) => {
            this.style = { ...this.style, wordWrap: true, wordWrapWidth: wrap };

            return this;
        };

        // ? RECALC USING TARGET SCALE
        /**
         * Set the height of text field
         * @param height height of your text field
         * @param increase_Y_scale allow to increase fontSize, default is `true`
         * @returns instance of {@link core.Text}
         */
        setHeight = (height: number, increase_Y_scale = true) => {
            const precision = 1;

            if (this.height > height) {
                while (this.height > height) {
                    this.style = { ...this.style, fontSize: Number(this.style.fontSize) - precision };
                }
            } else if (increase_Y_scale) {
                while (this.height < height) {
                    this.style = { ...this.style, fontSize: Number(this.style.fontSize) + precision };
                }
            }

            return this;
        };

        /**
         * Set the width of text field by changing font size, if this is one line
         * @param width width of your text field
         * @param increase_X_scale allow to increase fontSize, default is `true`
         * @returns instance of {@link core.Text}
         */
        setWidthNoWrap = (width: number, increase_X_scale = true) => {
            if (this.width > width) {
                while (this.width > width) {
                    this.style = { ...this.style, fontSize: Number(this.style.fontSize) - 1 };
                }
            } else if (increase_X_scale) {
                while (this.width < width) {
                    this.style = { ...this.style, fontSize: Number(this.style.fontSize) + 1 };
                }
            }

            return this;
        };

        /**
         * Method for setting text under another sprite
         *
         * @param target target for setting under.
         * @param padding padding from most top position of text field
         * @returns instance of {@link core.Text}
         */
        setUnder = (target: Sprite, padding = 0) => {
            // TODO ability to set under container / display object
            this.position.y = target.y + target.height * (target.anchor.y + 1) + padding;

            return this;
        };

        /**
         * Set the width and the height of text field. Combines {@link setWordWrap} and {@link setHeight}.
         * Used for fitting a text into rectangular field.
         *
         * - Autoupdate not allowed (if you change text, you need to recall this method)!
         *
         * @param width width of your text field
         * @param height height of your text field
         * @param increase_Y_scale allow to increase fontSize, default is `true`
         * @returns instance of {@link core.Text}
         */
        setBounds = (width: number, height: number, increase_Y_scale = true) => {
            this.setWordWrap(width);

            // if the text don`t have any spaces, word wrap doesn`t work
            if (!this.text.includes(' ')) {
                this.text = ' ' + this.text + ' ';
            }

            return this.setHeight(height, increase_Y_scale);
        };

        setCopyable = () => {
            if (this._copyable) return this;
            this._copyable = true;

            this.interactive = true;
            this.buttonMode = true;

            this.addListener('pointerdown', this.writeTextToClipboard);

            return this;
        };

        unsetCopyable = () => {
            if (!this._copyable) return this;
            this._copyable = false;

            this.interactive = false;
            this.buttonMode = false;

            this.removeListener('pointerdown', this.writeTextToClipboard);

            return this;
        };

        writeTextToClipboard = () => {
            navigator.clipboard.writeText(this.text);
        };

        insertOptionsData = (data: { [key: string]: any }) => {
            if (!this.origTextWithOptions) {
                this.origTextWithOptions = this.text;
            }

            let target = this.origTextWithOptions;

            Object.keys(data).forEach((key) => {
                target = target.replace(`{${key}}`, data[key]);
            });

            this.text = target;

            return this;
        };
    }

    /**
     * The core.Sprite class extends the {@link PIXI.Sprite} with some useful function.
     *
     * A sprite can be created from resources that you added in Assets.
     *
     * Typical usage:
     *
     * ```ts
     * let sprite = ResourceController.getSprite('image.png');
     * ```
     *
     * @class
     * @extends PIXI.Sprite
     * @memberof core
     */
    export class Sprite extends _Sprite {
        __width!: number;
        __height!: number;

        defaultVisible = true;
        showenParentIndex = 0;

        backupConfig: { [key in keyof Sprite]: unknown } = {} as {
            [key in keyof Sprite]: unknown;
        };

        constructor(texture?: _Texture | string | undefined) {
            super((texture = typeof texture === 'string' ? ResourceController.getTexture(texture) : texture));

            this.__width = this.width;
            this.__height = this.height;

            this.name = texture?.textureCacheIds[0] || 'Empty Texture';
        }

        onStateUpdate = () => {};

        getClone = (): core.Sprite => {
            return new core.Sprite(this.texture);
        };

        changeTexture = (texture: string | _Texture) => {
            texture = typeof texture === 'string' ? ResourceController.getTexture(texture) : texture;

            this.texture = texture;

            // this.width = this.__width;
            // this.height = this.__height;
            this.__width = texture.width;
            this.__height = texture.height;

            return this;
        };

        setCircleHitArea = () => {
            this.hitArea = new Circle(
                this.__width / 2 - this.__width * this.anchor.x,
                this.__height / 2 - this.__height * this.anchor.y,
                Math.max(this.__width, this.__height) / 2
            );

            return this;
        };

        trim = (frac: number) => {
            this.texture.trim = new Rectangle(frac, frac, this.__width - frac * 2, this.__height - frac * 2);

            this.texture.updateUvs();

            return this;
        };

        trimWithFrame = (frac: number) => {
            this.texture.frame = new Rectangle(
                this.texture.orig.x + frac,
                this.texture.orig.y + frac,
                this.texture.orig.width - frac * 2,
                this.texture.orig.height - frac * 2
            );

            this.texture.updateUvs();

            return this;
        };
    }
}
