import { DependencyList, useContext, useEffect, useState } from 'react';
import { Eventify } from '../../../Shared/Eventify';
import { ClientBuildEnvironment } from '../Types';
import { money as _money } from '../utils/utils';
import { appContext } from './contexts';

export const Money = () => {
    const app = useContext(appContext);
    const [value, setValue] = useState(app.state.balance);
    useEffect(() => {
        const eventify = new Eventify();
        eventify.listenTo(app.state, 'change:balance', () => setValue(app.state.balance));
        return () => {
            eventify.unlisten();
        };
    }, []);
    return _money(value, app.config.bet);
};

declare const __ENVIRONMENT__: ClientBuildEnvironment;
export function AddPropertiesToWindow(props: { [key: string]: unknown }) {
    if (__ENVIRONMENT__.EXPOSE_GLOBAL_VARS) {
        Object.assign(window, props);
    }
}

export function useEventify(effect: (eventify: InstanceType<typeof Eventify>) => void, deps?: DependencyList) {
    const [eventify] = useState(() => new Eventify());
    useEffect(() => {
        effect(eventify);
        return () => eventify.unlisten();
    }, deps);
}
