import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';
import de from './assets/locales/de.json';
import en from './assets/locales/en.json';
import uk from './assets/locales/uk.json';
import hi from './assets/locales/hi.json';
import ru from './assets/locales/ru.json';
import uz from './assets/locales/uz.json';

const resources = {
    en: {
        translation: en
    },
    de: {
        translation: de
    },
    uk: {
        translation: uk
    },
    hi: {
        translation: hi
    },
    ru: {
        translation: ru
    },
    uz: {
        translation: uz
    }
};

export type Language = keyof typeof resources;

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init(
        {
            // lng: 'en', // if you're using a language detector, do not define the lng option
            // debug: true,
            defaultNS: 'translation',
            fallbackLng: 'en',
            resources: resources,
            detection: {
                order: [
                    'querystring',
                    'path',
                    'cookie',
                    'localStorage',
                    'sessionStorage',
                    'navigator',
                    'htmlTag',
                    'subdomain'
                ],
                lookupQuerystring: 'lang', // -- TODO look into this one
                convertDetectedLanguage(lng) {
                    return lng.toLowerCase();
                }
            }
        },
        function (err, t) {
            err && console.error(err);
        }
    );
