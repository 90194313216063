import { MultiplierValue } from '../../Shared/MessageTypes';
import { IBetBasicEntry } from '../../Shared/TypesBetboard';

export class BetBoardEntry implements IBetBasicEntry {
    constructor(public id: string) {}
    userId: string = '0';
    currency: string = 'XXX';
    img: string = '';
    bet: number = 0;
    multiplier: MultiplierValue = 0;
    win: number = 0;
    time = Date.now();
    username: string = 'noname';
}
