import { useEffect, useState } from 'react';
import './Checkbox.scss';

interface Props {
    onChange?: (checked: boolean) => void;
    className?: string;
    checked?: boolean;
    disabled?: boolean;
}

export default ({ onChange = () => {}, className, checked = false, disabled = false }: Props) => {
    const [isChecked, setIsChecked] = useState(checked);
    useEffect(() => {
        if (isChecked !== checked) setIsChecked(checked);
    }, [checked]);
    return (
        <label
            aria-disabled={disabled}
            aria-checked={isChecked}
            className="a-checkbox flex-shrink-0 w-8 flex rounded-full"
        >
            <input
                disabled={disabled}
                className=""
                checked={isChecked}
                type="checkbox"
                onChange={(e) => {
                    setIsChecked(e.target.checked);
                    onChange(e.target.checked);
                }}
            />
            <div className="a-checkbox-check rounded-full w-full h-3 m-1"></div>
        </label>
    );
};
