import 'animate.css';
import { useContext, useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { Eventify } from '../../../../Shared/Eventify';
import { appContext } from '../contexts';
import CelebrationToast from './Celebration';

const celebration = ({ win = 0, multiplier = 0 }) =>
    toast.custom((t) => (
        <CelebrationToast
            multiplier={multiplier}
            win={win}
            dismiss={() => toast.dismiss(t.id)}
            style={{
                ...t.style,
                animation: t.visible ? 'fadeInDown 500ms ease' : 'backOutUp 1s ease'
            }}
        />
    ));

export const normalToast = ({ message }: { message: string }) =>
    toast((t) => (
        <div className="flex flex-row items-center place-content-stretch" style={t.style}>
            <div className="flex flex-row">{message}</div>
        </div>
    ));

export const errorToast = ({ message }: { message: string }) => {
    toast.dismiss();
    toast.error((t) => (
        <div className="flex flex-row items-center place-content-stretch" style={t.style}>
            <div className="flex flex-row">{message}</div>
        </div>
    ));
};

export const Notifications = () => {
    const app = useContext(appContext);
    useEffect(() => {
        const eventify = new Eventify();
        eventify.listenTo(app.network, 'notification', ({ type = '', message = '', cashoutData }) => {
            if (type === 'cashout') celebration(cashoutData);
            if (type === 'error') errorToast({ message });
        });
        return () => {
            eventify.unlisten();
            toast.dismiss();
        };
    }, []);

    return (
        <>
            <Toaster
                position="top-center"
                gutter={8}
                toastOptions={{
                    duration: 4000,
                    className: 'toast error'
                }}
                containerStyle={{
                    top: 20,
                    left: 0,
                    bottom: 0,
                    right: 0
                }}
            ></Toaster>
        </>
    );
};
