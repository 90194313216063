import { EventifyMixin } from '../../../Shared/Eventify';

enum Events {
    change,
    add,
    remove,
    all
}
export default class SuperMap<K, V> extends EventifyMixin(Map)<K, V> {
    static readonly Events = Events;
    readonly Events = Events;
    set(key: K, value: V) {
        const isHas = super.has(key);
        const result = super.set(key, value);
        this.emit(Events.all);
        this.emit(isHas ? Events.change : Events.add, key, value);
        return result;
    }
    delete(key: K) {
        const isHas = super.has(key);
        const value = super.get(key);
        const result = super.delete(key);
        if (isHas) {
            this.emit(Events.all);
            this.emit(Events.remove, key, value);
        }
        return result;
    }
    clear() {
        for (const entry of this) this.emit(Events.remove, entry[0], entry[1]);
        super.clear();
        this.emit(Events.all);
    }
}
