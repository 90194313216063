import { useContext, useRef } from 'react';
import { appContext } from './contexts';
import { useEventify } from './utils';

export const PingIndicator = () => {
    const app = useContext(appContext);
    const pingRef = useRef<HTMLSpanElement | null>(null);

    useEventify((eventify) => {
        eventify.listenTo(app, 'ping', (ping) => {
            if (pingRef.current) {
                pingRef.current.innerText = `${ping.toFixed(0)} ms`;
            }
        });
    });

    return (
        <span className="text-[#a8a29e]" ref={pingRef}>
            {(app.ping || 0).toFixed(0)} ms
        </span>
    );
};
