import { DefinePlugin } from 'webpack';
type definitions = DefinePlugin['definitions'];

export interface ClientBuildEnvironment extends definitions {
    MODE: 'dev' | 'prod';
    LOCALSERVER: boolean;
    EXPOSE_GLOBAL_VARS: boolean;
}

export interface ClientUrlParams {
    lang?: string;
    currency?: string;
    jurisdiction?: string;
    return_url?: string;
    token?: string;
    // websocket server
    server?: string;
    balance?: string;
}

export enum AppEvents {
    updateConfig
}
