import { useEffect, useState } from 'react';
import Bet from '../../Bet';
import BetPanel from '../BetPanel/BetPanel';

export interface BetPanelsWrapperProps {
    bets: Bet[];
}

export default function BetPanelsWrapper({ bets }: BetPanelsWrapperProps) {
    const [secondPanelVisible, setSecondPanelVisible] = useState(true);

    useEffect(() => {
        const savedVisibility = sessionStorage.getItem('secondPanelVisible');
        if (savedVisibility) {
            setSecondPanelVisible(savedVisibility === 'true');
        }
    }, []);

    // Update sessionStorage when visibility changes
    useEffect(() => {
        sessionStorage.setItem('secondPanelVisible', secondPanelVisible.toString());
    }, [secondPanelVisible]);

    const betPanelsLength = bets.length;
    const multipleBetPanels = betPanelsLength > 1;

    const showSecondPanel = () => setSecondPanelVisible(true);
    const hideSecondPanel = () => setSecondPanelVisible(false);

    return (
        <div
            className={`
            w-full mx-auto max-w-none  container p-0 mt-2
            ${
                secondPanelVisible
                    ? ' grid grid-cols-1 md:grid-cols-2 landscape:sm:grid-cols-2 gap-2'
                    : ' flex items-center justify-center w-full '
            }`}
        >
            {bets.map((bet, index) => {
                if (index === 1 && !secondPanelVisible) return null;
                return (
                    <BetPanel
                        key={bet.id}
                        bet={bet}
                        multipleBetPanels={multipleBetPanels}
                        firstBetPanel={index === 0}
                        lastBetPanel={index === 1 && secondPanelVisible}
                        onClickShowBetPanel={showSecondPanel}
                        onClickHideBetPanel={hideSecondPanel}
                        secondPanelVisible={secondPanelVisible}
                    />
                );
            })}
        </div>
    );
}
