export const fetcher = async (url: string, options: RequestInit = {}) => {
    // const baseUrl = 'https://api.dev.gameround.gamestudio.easyplug.io/api';
    // const baseUrl = 'https://localhost:5003/api';
    // const baseUrl = 'https://gameround.ep.vvy.dev/api';
    const baseUrl = `${process.env.GAMEROUND_API_URL}/api`;
    const apiKey = '816f2ba8-b53a-4d6a-8af8-767fe30301a7';
    const browserUrl = new URL(location.href);
    const token = browserUrl.searchParams.get('token');

    const headers = {
        ...options.headers,
        'Content-Type': 'application/json',
        'X-Api-Key': apiKey,
        // TODO
        // Disables preflight
        'Access-Control-Allow-Headers': 'Authorization, *',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Max-Age': '86400',
        Authorization: 'asdf'
    };

    const apiURL = new URL(`${baseUrl}${url}`);

    if (token) {
        apiURL.searchParams.set('Token', token);
    }

    const response = await fetch(apiURL.toString(), {
        ...options,
        headers
    });

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.json();
};

export const swrFetcher = (...args: Parameters<typeof fetcher>) => fetcher(...args);

export const fetcherPlainText = async (url: string, options: RequestInit = {}, overideBaseUrl?: string) => {
    const baseUrl = overideBaseUrl || process.env.GAMEROUND_API_URL;
    const response = await fetch(`${baseUrl}${url}`, options);

    if (!response.ok) {
        throw new Error(response.statusText);
    }

    return response.text();
};
