import { Tab } from '@headlessui/react';
import { Fragment, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Scrollbar } from 'react-scrollbars-custom';
import { ByArgument, PeriodArgument } from '../../../../Shared/MessageTypes';
import { getMultipliersLeaderboard, getWinsLeaderboard } from '../../../../Shared/services/queries';
import { appContext } from '../contexts';
import Avatar from '../other/Avatar';
import { MultiplierBadge } from '../other/MultiplierBadge';
import Username from '../other/Username';
import { ShieldIcon } from '../../../assets/icons';
import { ShieldSecurityIcon } from '../../../assets/icons/ShieldSecurityIcon';
import './BetLog.scss';
import { logInfo } from '../../utils/utils';

export function TopBetsContainer() {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    return (
        <Tab.Group defaultIndex={0} onChange={(index) => setSelectedIndex(index)}>
            <div className={`flex w-full items-center justify-center`}>
                <div
                    className="relative flex items-center justify-center space-x-1 h-8 bg-[#27272A] w-full rounded-full mr-2 ml-2"
                    style={{ maxWidth: '400px' }}
                >
                    {/* Sliding Indicator */}
                    <div
                        className="absolute bg-[#3F3F46] rounded-full transition-all items-start duration-300 ring-1 ring-[#fefce81a]"
                        style={{
                            width: `${100 / 2}%`,
                            transform: `translateX(${selectedIndex * 100 - 50}%)`,
                            height: '98%'
                        }}
                    />
                    <Tab.List className="flex w-full z-10">
                        <Tab
                            key={0}
                            as="button"
                            className={({ selected }) =>
                                `relative text-center justify-center focus:outline-none z-20 flex-1 text-sm font-medium rounded-full ${
                                    selected ? 'active-tab-item' : 'tab-item'
                                }`
                            }
                        >
                            {t('sidebar.wins')}
                        </Tab>
                        <Tab
                            key={1}
                            as="button"
                            className={({ selected }) =>
                                `relative text-center justify-center focus:outline-none z-20 flex-1 text-sm font-medium rounded-full ${
                                    selected ? 'active-tab-item' : 'tab-item'
                                }`
                            }
                        >
                            {t('sidebar.multipliers')}
                        </Tab>
                    </Tab.List>
                </div>
            </div>
            <Tab.Panels as={Fragment}>
                <Tab.Panel key={0} className="flex flex-col flex-grow overflow-y-hidden">
                    <BetTopTabs by={ByArgument.wins} />
                </Tab.Panel>
                <Tab.Panel key={1} className="flex flex-col flex-grow overflow-y-hidden">
                    <BetTopTabs by={ByArgument.multipliers} />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}

function BetTopTabs({ by }: { by: ByArgument }) {
    const { t } = useTranslation();
    const [selectedIndex, setSelectedIndex] = useState(0);

    return (
        <Tab.Group defaultIndex={0} onChange={(index) => setSelectedIndex(index)}>
            <div className={`flex w-full items-center justify-center`}>
                <div
                    className="relative flex w-full items-center justify-center space-x-1 h-8 bg-[#27272A] rounded-full m-2"
                    style={{ maxWidth: '400px' }}
                >
                    {/* Sliding Indicator */}
                    <div
                        className="absolute bg-[#3F3F46] rounded-full transition-all items-start duration-300 ring-1 ring-[#fefce81a]"
                        style={{
                            width: `${100 / 4}%`,
                            transform: `translateX(${selectedIndex * 100 - 150}%)`,
                            height: '98%'
                        }}
                    />
                    <Tab.List className="flex w-full z-10">
                        <Tab
                            key={0}
                            as="button"
                            className={({ selected }) =>
                                `relative text-center justify-center focus:outline-none z-20 flex-1 text-sm font-medium rounded-full ${
                                    selected ? 'active-tab-item' : 'tab-item'
                                }`
                            }
                        >
                            {t('sidebar.day')}
                        </Tab>
                        <Tab
                            key={1}
                            as="button"
                            className={({ selected }) =>
                                `relative text-center justify-center focus:outline-none z-20 flex-1 text-sm font-medium rounded-full ${
                                    selected ? 'active-tab-item' : 'tab-item'
                                }`
                            }
                        >
                            {t('sidebar.month')}
                        </Tab>
                        <Tab
                            key={2}
                            as="button"
                            className={({ selected }) =>
                                `relative text-center justify-center focus:outline-none z-20 flex-1 text-sm font-medium rounded-full ${
                                    selected ? 'active-tab-item' : 'tab-item'
                                }`
                            }
                        >
                            {t('sidebar.year')}
                        </Tab>
                        <Tab
                            key={3}
                            as="button"
                            className={({ selected }) =>
                                `relative text-center justify-center focus:outline-none z-20 flex-1 text-sm font-medium rounded-full ${
                                    selected ? 'active-tab-item' : 'tab-item'
                                }`
                            }
                        >
                            {t('sidebar.all')}
                        </Tab>
                    </Tab.List>
                </div>
            </div>
            <Tab.Panels as={Fragment}>
                <Tab.Panel key={0} className="flex flex-col flex-grow h-full overflow-y-hidden">
                    <BetTopEntries period={PeriodArgument.day} by={by} />
                </Tab.Panel>
                <Tab.Panel key={1} className="flex flex-col flex-grow h-full overflow-y-hidden">
                    <BetTopEntries period={PeriodArgument.month} by={by} />
                </Tab.Panel>
                <Tab.Panel key={2} className="flex flex-col flex-grow h-full overflow-y-hidden">
                    <BetTopEntries period={PeriodArgument.year} by={by} />
                </Tab.Panel>
                <Tab.Panel key={3} className="flex flex-col flex-grow h-full overflow-y-hidden">
                    <BetTopEntries period={PeriodArgument.all} by={by} />
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}

function BetTopEntries({ period, by }: { period: PeriodArgument; by: ByArgument }) {
    // TODO add revalidation if visible with given interval
    let bets: any[] = [];

    switch (by) {
        case ByArgument.wins:
            ({ bets } = getWinsLeaderboard(period));
            break;
        case ByArgument.multipliers:
            ({ bets } = getMultipliersLeaderboard(period));
            break;
    }

    const transformedBets = useMemo(
        () =>
            (bets || []).map((b, i) => {
                const date = new Date(b.created);

                return {
                    ...b,
                    username: b.username,
                    time: date.getTime(),
                    date: date.getTime(),
                    currency: b.currency,
                    win: b.winFinalAmount,
                    bet: b.finalAmount,
                    id: b.id, // betId
                    roundId: b.gameRoundId,
                    // TODO map these with actual values from BE when introduced
                    isMine: false,
                    img: '',
                    userId: b.playerId,
                    avatarId: b.avatarId
                };
            }),
        [bets]
    );

    return (
        <Scrollbar
            className="flex flex-row h-full overflow-y-scroll px-1.5 custom-scrollbar-container"
            thumbYProps={{
                renderer: (props) => {
                    const { elementRef, style, ...restProps } = props;
                    return (
                        <div
                            {...restProps}
                            ref={elementRef}
                            style={{
                                ...style,
                                backgroundColor: '#ffda00'
                            }}
                        />
                    );
                }
            }}
        >
            <div className="flex flex-col w-full gap-1.5 text-sm" style={{ paddingRight: '4px' }}>
                {transformedBets.map((betInfo) => {
                    switch (by) {
                        case ByArgument.wins:
                            return <BetTopEntry key={betInfo.id} data={betInfo} />;
                        case ByArgument.multipliers:
                            return <BetMultiplier key={betInfo.id} data={betInfo} />;
                    }
                })}
            </div>
        </Scrollbar>
    );
}

interface IBetTopEntryProps {
    id: string;
    roundId: string;
    userId: string;
    username: string;
    bet: number;
    multiplier: number;
    win: number;
    isMine: boolean;
    currency: string;
    date: number;
    img: string;
    time: number;
    gameRoundMultiplier: number;
    avatarId: number;
}
function BetTopEntry({ data }: { data: IBetTopEntryProps }) {
    const app = useContext(appContext);
    logInfo('date', data.date);
    const modalOpened = useState(false);
    return (
        <div className="flex flex-col border border-transparent rounded-md text-[#fefce8] panel-bg-alt">
            <div className="flex flex-row relative m-1">
                <div className="absolute right-0 top-0.5" onClick={modalOpened[1].bind(null, true)}>
                    <ShieldSecurityIcon />
                </div>
                <div className="flex flex-wrap align-middle justify-center items-center content-center gap-1 w-1/3 flex-0">
                    <Avatar data-avatarid={data.avatarId.toString()} />

                    <div className="flex flex-grow justify-center w-full">
                        <Username name={data.username} data-userid={data.userId} />
                    </div>
                </div>
                <div className="flex flex-col items-center w-2/3 gap-1">
                    <div className="flex flex-row w-full gap-2">
                        <div className="flex whitespace-nowrap justify-end w-1/2 text-[#a8a29e]">
                            Bet {data.currency}:
                        </div>
                        <div className="flex justify-start w-1/2">{Number(data.bet || 0).toFixed(2)}</div>
                    </div>
                    <div className="flex flex-row w-full gap-2">
                        <div className="flex whitespace-nowrap justify-end w-1/2 text-[#a8a29e]">Cashed out:</div>
                        <div className="flex justify-start w-1/2">
                            <MultiplierBadge multiplier={data.multiplier} />
                        </div>
                    </div>
                    <div className="flex flex-row w-full gap-2">
                        <div className="flex whitespace-nowrap justify-end w-1/2 text-[#a8a29e]">
                            Win {data.currency} :
                        </div>
                        <div className="flex justify-start w-1/2">{Number(data.win || 0).toFixed(2)}</div>
                    </div>
                </div>
            </div>
            <div className="flex w-full flex-row rounded-b-md gap-2 p-2 bg-black bg-opacity-50">
                <div className="flex text-[#a8a29e]">
                    {new Date(data.date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                </div>
                <div className="flex">
                    <span className="text-[#a8a29e] mr-1">Round:</span>
                    <span>{data.gameRoundMultiplier}x</span>
                </div>
                {/* <button className="flex justify-end flex-1">💬</button> */}
            </div>
        </div>
    );
}

function BetMultiplier({ data }: { data: IBetTopEntryProps }) {
    const modalOpened = useState(false);
    return (
        <div className="flex flex-col border border-transparent rounded-md panel-bg-alt">
            <div className="flex flex-row relative m-1">
                <div className="absolute right-0 top-0.5" onClick={modalOpened[1].bind(null, true)}>
                    <ShieldSecurityIcon />
                </div>
                <div className="flex flex-wrap align-middle justify-start items-center content-center gap-1 w-1/3 flex-0">
                    <Avatar data-avatarid={data.avatarId.toString()} />
                    <Username name={data.username} data-userid={data.userId} />
                </div>
                <div className="flex flex-col items-center w-2/3">
                    <div className="flex flex-row w-full gap-2">
                        <div className="flex whitespace-nowrap justify-end w-3/5">Multiplier:</div>
                        <div className="flex justify-start w-2/5">
                            <MultiplierBadge multiplier={data.multiplier} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap w-full rounded-b-md gap-2 p-1 bg-black bg-opacity-50">
                <div className="flex">{new Date(data.date).toDateString()}</div>
                {/* <button className="flex justify-end flex-1">💬</button> */}
            </div>
        </div>
    );
}
