export const CrossIcon = ({ width = 16, height = 16, color = 'currentColor' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1280 1280"
            width={width}
            height={height}
            fill={color}
        >
            <g transform="translate(0,1280) scale(0.1,-0.1)">
                <path
                    d="M1595 12788 c-173 -43 -252 -77 -350 -151 -33 -25 -292 -279 -575
                    -564 -368 -370 -525 -534 -549 -575 -64 -110 -106 -241 -118 -369 -6 -61 24
                    -214 59 -304 52 -133 9 -88 2209 -2290 1166 -1166 2119 -2127 2119 -2135 0 -8
                    -951 -967 -2114 -2130 -1162 -1163 -2128 -2137 -2145 -2163 -75 -111 -137
                    -321 -128 -432 11 -127 53 -260 118 -373 48 -81 1100 -1133 1181 -1181 109
                    -63 241 -105 369 -118 106 -10 308 48 428 124 38 23 751 729 2171 2149 1163
                    1163 2122 2114 2130 2114 8 0 967 -951 2130 -2114 1420 -1420 2133 -2126 2171
                    -2149 120 -76 322 -134 428 -124 128 13 260 55 369 118 81 48 1133 1100 1181
                    1181 48 83 77 160 100 268 27 120 26 148 -3 273 -28 117 -50 173 -101 254 -26
                    40 -680 702 -2151 2173 -1163 1163 -2114 2122 -2114 2130 0 8 951 967 2114
                    2130 1418 1419 2126 2133 2149 2171 76 119 134 321 124 428 -12 127 -54 258
                    -118 369 -48 81 -1100 1133 -1181 1181 -113 65 -246 107 -373 118 -111 9 -321
                    -53 -432 -128 -26 -17 -1000 -983 -2163 -2145 -1163 -1163 -2122 -2114 -2130
                    -2114 -8 0 -967 951 -2130 2114 -1471 1471 -2133 2125 -2173 2151 -81 51 -137
                    73 -254 101 -107 25 -180 28 -248 12z"
                />
            </g>
        </svg>
    );
};
