export const ShieldSecurityIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1024 1024"
            width="16"
            height="16"
        >
            <g>
                <g id="Layer_1">
                    <path
                        fill="#ffda00"
                        d="M512.5,1024C32.7,838.1,76.3,204.8,76.3,204.8,366.7,258.3,512.5,0,512.5,0,512.5,0,658,258.3,947.7,204.8c0,0,43.5,633.3-435.3,819.2Z"
                    />
                    <path
                        fill="#101010"
                        d="M286.1,542.1l150.6,150.6,301.2-301.2-60.2-60.2-240.9,240.9-90.4-90.4-60.2,60.2Z"
                    />
                </g>
            </g>
        </svg>
    );
};
