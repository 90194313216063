export enum SrvState {
    STARTED = 'STARTED',
    STOPPING = 'STOPPING',
    STOPPED = 'STOPPED'
}

export enum RoundState {
    INIT = 'INIT',
    BET_OPENED = 'BET_OPENED', // end game
    BET_CLOSED = 'BET_CLOSED', // start game
    ROUND_STARTED = 'ROUND_STARTED',
    ROUND_ENDED = 'ROUND_ENDED'
}

export enum RoundStateEvent {
    EV_BET_OPENED = 'EV_BET_OPENED', // start betting window
    EV_BET_CLOSED = 'EV_BET_CLOSED', // end betting window
    EV_ROUND_STARTED = 'EV_ROUND_STARTED', // start game
    EV_ROUND_ENDED = 'EV_ROUND_ENDED', // end game
    EV_MULTIPLIER = 'EV_MULTIPLIER' //"MULTIPLIER-UPDATE"
}

// export interface ICalculatorConfig {
//     maxWin: number;
//     minWin: number;
//     accuracy: number;
//     aValue: number;
// }
export interface IParticipation {
    userId: string;
    roundId: string;
    seed: string;
    date: number;
    bets: IBetInfo[];
    seedPosition: number;
}
export interface IBetInfo {
    id: string;
    userId: string;
    roundId: string;
    date: number;
    amount: number;
    multiplier: number;
    bet: number;
    win: number;
    username: string;
    currency: string;
}

export interface IRoundSource {
    id: string;
    betOpen: number;
    betClose: number;
    roundStart: number;
    roundEnd: number;
    multiplier: number;
    serverSeed: string;
    nextServerSeed: string;
    participants: IParticipation[];
    bets: IBetInfo[];
}

export interface IRoundInfo {
    id: string;
    betOpen: number;
    betClose: number;
    roundStart: number;
    roundEnd: number;
    multiplier: number;
    serverSeed: string;
    participants: IParticipation[];
    bets: IBetInfo[];
    hash: string;
    hex: string;
    decimal: number;
}
