import { useContext, useEffect, useState } from 'react';
import { Eventify } from '../../../Shared/Eventify';
import { ClientPlayMode } from '../../../Shared/MessageTypes';
import { appContext } from './contexts';

export default () => {
    const app = useContext(appContext);
    const [visible, setVisible] = useState(false);

    function setState() {
        const v = app.state.clientPlayMode === ClientPlayMode.demo;
        visible !== v && setVisible(v);
    }
    setState();

    useEffect(() => {
        const eventify = new Eventify();
        eventify.listenTo(app.state, 'clientPlayMode', setState);
        return () => eventify.unlisten();
    }, []);

    if (!visible) return null;
    return (
        <div className="flex absolute w-full justify-center rounded-t-xl" style={{ background: 'rgba(229,148,7,.8)' }}>
            FUN MODE
        </div>
    );
};
