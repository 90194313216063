import React from 'react';

export const HistoryIcon: React.FC = () => {
    return (
        <svg
            className="transition-transform duration-300 ease-in-out"
            width="14px"
            height="14px"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            id="Layer_1"
            data-name="Layer 1"
            viewBox="0 0 24 24"
        >
            <path d="M12,0C7.96,0,4.211,2.066,2,5.413V0H1V5.5c0,.827,.673,1.5,1.5,1.5h5.5v-1H2.808C4.831,2.909,8.282,1,12,1c6.065,0,11,4.935,11,11s-4.935,11-11,11S1,18.065,1,12H0c0,6.617,5.383,12,12,12s12-5.383,12-12S18.617,0,12,0Z" />
            <polygon points="11 6 11 12.207 14.646 15.854 15.354 15.146 12 11.793 12 6 11 6" />
        </svg>
    );
};
