import { createRef, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { adjustValueByPrecision } from '../../../../Shared/utils';
import { config } from '../../config';
import { clamp, logInfo, money } from '../../utils/utils';
import { appContext } from '../contexts';

export default function BetInput({
    defaultValue,
    enabled,
    onChange,
    onMount = () => {},
    min = Number.NEGATIVE_INFINITY,
    max = Number.POSITIVE_INFINITY
}: {
    defaultValue: number;
    enabled?: boolean;
    onChange: (value: number) => void;
    onMount?: (fn: (newValue: number) => void) => void;
    min?: number;
    max?: number;
}) {
    const timerRef = useRef<unknown>();
    const refInput = createRef<HTMLInputElement>();
    const app = useContext(appContext);

    // These two may not be needed as memo values
    const minVal = useMemo(() => {
        return min; // || adjustValueByPrecision(app.config.bet.min);
    }, [min]);

    const maxVal = useMemo(() => {
        return max; // adjustValueByPrecision(app.config.bet.max);
    }, [max]);

    const step = useMemo(() => {
        return adjustValueByPrecision(app.config.bet.step, app.config);
    }, [app.config.bet.step]);

    const getFormattedValue = useCallback(
        (value: number | string) => {
            return money(Math.trunc(Number(value) * Math.pow(10, config.bet.precision)), config.bet, true);
        },
        [config.bet]
    );

    useEffect(() => clearTimers, []);
    useEffect(() => {
        if (refInput.current) {
            refInput.current!.value = money(defaultValue, config.bet, true);
        }
    }, [minVal, maxVal, step, refInput, defaultValue]);

    function clearTimers() {
        clearInterval(timerRef.current as number);
        clearTimeout(timerRef.current as number);
    }

    function repeated(fn: Function) {
        return () => {
            clearTimers();
            timerRef.current = setTimeout(() => (timerRef.current = setInterval(fn, 30)), 350);
            return fn();
        };
    }

    function onBlur(inputValue?: number) {
        let newValue = inputValue;

        // fall backs
        // if there is no new value, the input was changed manually
        if (newValue === undefined) {
            newValue = parseFloat(sanitizeNumber(refInput.current!.value));
        }

        if (refInput.current && !Number.isNaN(newValue)) {
            if (newValue) {
                // if out of bounds, clamp --> show notification
                let newBetValue = clamp(newValue, minVal, maxVal);

                // this way we can still write . in the number
                if (newBetValue !== newValue) {
                    logInfo('here we go !===');
                    refInput.current!.value = newBetValue.toFixed(2);
                } else if (inputValue) {
                    logInfo('here we go inputValue');
                    refInput.current!.value = getFormattedValue(inputValue);
                }

                newBetValue = Math.trunc(Math.round(newBetValue * Math.pow(10, config.bet.precision)));
                onChange(newBetValue);
            }

            isFocused.current = false;
            refInput.current!.value = getFormattedValue(sanitizeNumber(refInput.current!.value));
        }
    }

    function sanitizeNumber(value: string): string {
        return value.replace(/,/g, '');
    }

    // allow only to have 2 decimal places
    const onInput = () => {
        const value = refInput.current!.value;

        if (!value || !value.length || !value.includes('.')) {
            return;
        }

        const beforeDot = value.split('.')[0];
        const afterDot = value.split('.')[1];

        if (afterDot?.length > 2) {
            refInput.current!.value = [beforeDot, '.', afterDot?.slice(0, 2)].join('');
        }
    };

    const increaseBet = () => {
        const sanitizedValue = (refInput.current?.value || '').replace(',', '');
        onBlur(Number(sanitizedValue) + step);
    };
    const decreaseBet = () => {
        const sanitizedValue = (refInput.current?.value || '').replace(',', '');
        onBlur(Number(sanitizedValue) - step);
    };
    const isMouseDown = useRef(false);
    const isFocused = useRef(false);

    // This is needed so we can adjust the value by clicking predefined bet value buttons
    onMount((value) => {
        const adjusted = adjustValueByPrecision(value, app.config);

        if (adjusted !== Number(refInput.current?.value)) {
            onBlur(adjusted);
        }
    });

    const buttonCommonClasses =
        'absolute top-1/2 transform -translate-y-1/2 w-6 h-6 flex justify-center disabled:opacity-40';

    return (
        <div
            className="relative flex flex-row text-[#a8a29e]  items-center rounded-full px-7"
            style={{ background: '#000000b3' }}
        >
            <input
                ref={refInput}
                disabled={!enabled}
                className="w-full bg-transparent text-center p-2 rounded-full disabled:opacity-40 text-[#fefce8] focus:outline-none"
                onInput={() => onInput()}
                onBlur={() => onBlur()}
                onMouseUp={() => (isMouseDown.current = false)}
                onMouseDown={() => (isMouseDown.current = true)}
                onFocus={() => (isFocused.current = true)}
                onKeyDown={(e) => {
                    const allowedKeys = [
                        'Enter',
                        'Escape',
                        'ArrowUp',
                        'ArrowDown',
                        'ArrowLeft',
                        'ArrowRight',
                        'Backspace',
                        'Tab',
                        'Delete'
                    ];

                    if (e.key === 'Enter' || e.key === 'Escape') {
                        refInput.current!.blur();
                    } else if (allowedKeys.includes(e.key)) {
                        return;
                    } else if (!/[0-9.]/.test(e.key) || (e.key === '.' && refInput.current!.value.includes('.'))) {
                        e.preventDefault();
                    }
                }}
            />
            <button
                disabled={!enabled}
                onMouseDown={repeated(decreaseBet)}
                onMouseUp={clearTimers}
                onMouseLeave={clearTimers}
                className={`${buttonCommonClasses} left-2`}
            >
                <span className="flex items-center font-medium text-lg text-[ rounded-full border-2 border-[#a8a29e] h-6 w-6 justify-center text-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="h-6 w-6"
                        fill="none"
                        stroke="#a8a29e"
                        strokeWidth="2"
                    >
                        <line x1="7" y1="12" x2="17" y2="12" />
                    </svg>
                </span>
            </button>
            <button
                disabled={!enabled}
                onMouseDown={repeated(increaseBet)}
                onMouseUp={clearTimers}
                onMouseLeave={clearTimers}
                className={`${buttonCommonClasses} right-2`}
            >
                <span className="flex font-medium #a8a29e text-lg rounded-full border-2 border-[#a8a29e] h-6 w-6 justify-center items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className="h-6 w-6"
                        fill="none"
                        stroke="#a8a29e"
                        strokeWidth="2"
                    >
                        <line x1="12" y1="6" x2="12" y2="18" />
                        <line x1="6" y1="12" x2="18" y2="12" />
                    </svg>
                </span>
            </button>
        </div>
    );
}
