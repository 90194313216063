/**
 * Converts a hexadecimal color value to an RGBA string.
 *
 * @param hex - The hexadecimal color value (e.g., 0x34b4ff).
 * @param opacity - The opacity value (0-255).
 * @returns The RGBA color string (e.g., "rgba(52, 180, 255, 0.75)").
 */
export function hexToRgba(hex: number, opacity: number): string {
    const r = (hex >> 16) & 0xff;
    const g = (hex >> 8) & 0xff;
    const b = hex & 0xff;
    return `rgba(${r}, ${g}, ${b}, ${opacity / 255})`;
}
