import { Dialog } from '@headlessui/react';
import { t } from 'i18next';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import Button from '../other/Button';
import { DialogContext } from '../other/Dialog';

export default function ModalGamerules() {
    const { close } = useContext(DialogContext);

    return (
        <div>
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 modal-header">
                {t('gameRules.howToPlay.howToPlay')} {/* Game Rules Title */}
            </Dialog.Title>

            {/* How to Play Section */}
            <div className="ml-2 mb-6 min-h-60 h-[50vh] overflow-auto" style={{ minHeight: '200px' }}>
                <div className="text-blue-600 text-xl pl-3 mt-2">{t('gameRules.howToPlay.howToPlay')}</div>
                <div className="pl-7 pb-2 pr-4">
                    <ol className="list-decimal">
                        <li>
                            <Trans i18nKey="gameRules.howToPlay.bet" components={{ strong: <strong /> }} />
                        </li>
                        <li>
                            <Trans i18nKey="gameRules.howToPlay.watch" components={{ strong: <strong /> }} />
                        </li>
                        <li>
                            <Trans i18nKey="gameRules.howToPlay.cashOut" components={{ strong: <strong /> }} />
                        </li>
                    </ol>
                </div>

                {/* Additional Details */}
                <div className="pl-7 pb-2 pr-4">
                    <ul className="list-disc">
                        <li>{t('gameRules.howToPlay.howToPlay_1')}</li>
                        <li>{t('gameRules.howToPlay.howToPlay_2')}</li>
                        <li>{t('gameRules.howToPlay.howToPlay_3')}</li>
                    </ul>
                </div>

                <div className="text-blue-600 text-xl pl-3 mt-4">{t('gameRules.gameFunctions.title')}</div>
                <div className="ml-4">
                    <Trans i18nKey="gameRules.gameFunctions.betCashOut" components={{ strong: <strong /> }} />
                </div>
                <div className="pl-7 pb-2 pr-4">
                    <ul className="list-disc">
                        <li>{t('gameRules.gameFunctions.betInstructions_1')}</li>
                        <li>{t('gameRules.gameFunctions.betInstructions_2')}</li>
                        <li>{t('gameRules.gameFunctions.betInstructions_3')}</li>
                        <li>{t('gameRules.gameFunctions.betInstructions_4')}</li>
                        <li>{t('gameRules.gameFunctions.betInstructions_5')}</li>
                        <li>{t('gameRules.gameFunctions.betInstructions_6')}</li>
                    </ul>
                </div>

                {/* Auto Play & Auto Cash Out Section */}
                <div className="text-blue-600 text-xl pl-3 mt-4">{t('gameRules.autoPlay.title')}</div>
                <div className="pl-6 pb-2 pr-4">
                    <ul className="list-disc">
                        <li>{t('gameRules.autoPlay.autoPlayInstructions_1')}</li>
                        <li>{t('gameRules.autoPlay.autoPlayInstructions_2')}</li>
                        <li>{t('gameRules.autoPlay.autoPlayInstructions_3')}</li>
                        <li>{t('gameRules.autoPlay.autoPlayInstructions_4')}</li>
                        <li>{t('gameRules.autoPlay.autoPlayInstructions_5')}</li>
                        <li>{t('gameRules.autoPlay.autoPlayInstructions_6')}</li>
                        <li>{t('gameRules.autoPlay.autoPlayInstructions_7')}</li>
                        <li>{t('gameRules.autoPlay.autoPlayInstructions_8')}</li>
                    </ul>
                </div>

                {/* Live Bets & Statistics Section */}
                <div className="text-blue-600 text-xl pl-3 mt-4">{t('gameRules.liveBetsStats.title')}</div>
                <div className="pl-6 pb-2 pr-4">
                    <ul className="list-disc">
                        <li>{t('gameRules.liveBetsStats.liveBetsInstructions_1')}</li>
                        <li>{t('gameRules.liveBetsStats.liveBetsInstructions_2')}</li>
                        <li>{t('gameRules.liveBetsStats.liveBetsInstructions_3')}</li>
                    </ul>
                </div>

                {/* Randomization (RNG) Section */}
                <div className="text-blue-600 text-xl pl-3 mt-4">{t('gameRules.randomization.title')}</div>
                <div className="pl-6 pb-2 pr-4">
                    <p>{t('gameRules.randomization.description')}</p>
                </div>

                {/* Return to Player (RTP) Section */}
                <div className="text-blue-600 text-xl pl-3 mt-4">{t('gameRules.rtp.title')}</div>
                <div className="pl-6 pb-2 pr-4">
                    <p>{t('gameRules.rtp.description')}</p>
                </div>

                {/* Game Menu Section */}
                <div className="text-blue-600 text-xl pl-3 mt-4">{t('gameRules.gameMenu.title')}</div>
                <div className="pl-6 pb-2 pr-4">
                    <ul className="list-disc">
                        <li>{t('gameRules.gameMenu.menuInstructions_1')}</li>
                        <li>{t('gameRules.gameMenu.menuInstructions_2')}</li>
                        <li>{t('gameRules.gameMenu.menuInstructions_3')}</li>
                        <li>{t('gameRules.gameMenu.menuInstructions_4')}</li>
                        <li>{t('gameRules.gameMenu.menuInstructions_5')}</li>
                        <li>{t('gameRules.gameMenu.menuInstructions_6')}</li>
                    </ul>
                </div>

                {/* Other Section */}
                <div className="text-blue-600 text-xl pl-3 mt-4">{t('gameRules.other.title')}</div>
                <div className="pl-6 pb-2 pr-4">
                    <ul className="list-disc">
                        <li>{t('gameRules.other.otherInstructions_1')}</li>
                        <li>{t('gameRules.other.otherInstructions_2')}</li>
                    </ul>
                </div>
            </div>
            {/* Bottom Bar with Close Button */}
            <div className="bottom-bar">
                <Button onClick={close}>Got it, thanks!</Button>
            </div>
        </div>
    );
}
