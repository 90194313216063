import { Dialog } from '@headlessui/react';
import { t } from 'i18next';
import Cookies from 'js-cookie';
import { useContext } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { sendAvatarChange } from '../../../../Shared/services/queries';
import { getColorForMultiplier } from '../../config';
import { MicroColor } from '../../utils/Microcolor';
import { appContext } from '../contexts';
import Button from '../other/Button';
import { DialogContext } from '../other/Dialog';

export default function ModalAvatarChoose() {
    const app = useContext(appContext);
    const { close } = useContext(DialogContext);

    const changeAvatar = async (avatarId: number) => {
        app.avatarId = avatarId;

        Cookies.set('avatarId', avatarId.toString());

        // call api with the change
        await sendAvatarChange(avatarId);

        close && close();
    };

    return (
        <>
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 modal-header">
                Choose game avatar
            </Dialog.Title>

            <div className="flex flex-wrap justify-center gap-2 my-4 p-2.5 min-h-60 h-[50vh] overflow-auto custom-scrollbar-container">
                <Scrollbar
                    className="w-full h-full px-1.5"
                    thumbYProps={{
                        renderer: (props) => {
                            const { elementRef, style, ...restProps } = props;
                            return (
                                <div
                                    {...restProps}
                                    ref={elementRef}
                                    style={{
                                        ...style,
                                        backgroundColor: '#ffda00'
                                    }}
                                />
                            );
                        }
                    }}
                >
                    <div className="flex flex-wrap justify-center gap-2 w-full h-full">
                        {Array.from({ length: 34 }).map((_, i) => (
                            <button
                                key={i}
                                className="relative flex justify-center items-center w-24 h-24 aspect-square rounded-full overflow-hidden"
                                onClick={() => changeAvatar(i + 1)}
                            >
                                <div
                                    className="absolute inset-0 m-[1px] rounded-full"
                                    style={{
                                        background: MicroColor.temp.fromINT(getColorForMultiplier(2 ** i)).getHEX()
                                    }}
                                ></div>

                                <div className={`relative flex w-24 h-24 avatar${i < 9 ? '0' : ''}${i + 1}`}></div>
                            </button>
                        ))}
                    </div>
                </Scrollbar>
            </div>

            <div className="bottom-bar">
                <Button onClick={close}>{t('cancel')}</Button>
            </div>
        </>
    );
}
