import { HTMLAttributes } from 'react';

export default function Username(props: { 'data-userid': string; name: string } & HTMLAttributes<HTMLDivElement>) {
    const { name } = props;
    const displayName = name ? `${name?.slice(0, 2)}***${name?.slice(-2)}` : 'noname';

    return (
        <div className="" {...props}>
            {displayName}
        </div>
    );
}
