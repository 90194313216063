// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OmitFirst<T extends any[]> = T extends [any, ...infer R] ? R : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type OmitLast<T extends any[]> = T extends [...infer R, any] ? R : never;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Constructor<T = {}> = new (...args: any[]) => T;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LISTENER = (...agr: any[]) => void;
type EVENT = string | number;

export function EventifyMixin<T extends Constructor<{}>>(Base: T) {
    return class EventifyBase extends Base {
        events: { [event: EVENT]: LISTENER[] } = {};
        ev: Array<[EventifyBase, EVENT, LISTENER]> = [];

        on<T = LISTENER>(...rest: [EVENT, ...EVENT[], T]): T {
            if (rest.length < 2) throw new Error('Eventify.on() need at least 2 arguments');
            const length = rest.length;
            const listener = rest[length - 1] as LISTENER;
            for (let i = 0; length - 1 > i; i++) {
                const event = rest[i] as EVENT;
                if (typeof this.events[event] !== 'object') {
                    this.events[event] = [];
                }
                this.events[event].push(listener);
            }
            return listener as T;
        }

        removeListener(event: EVENT, listener: LISTENER) {
            if (typeof this.events[event] === 'object') {
                const idx = this.events[event].indexOf(listener);
                if (idx > -1) {
                    this.events[event].splice(idx, 1);
                }
                if (this.events[event].length === 0) {
                    delete this.events[event];
                }
            }
        }
        emit(event: EVENT, ...rest: unknown[]) {
            if (typeof this.events[event] === 'object') {
                const listeners = this.events[event].slice();

                for (const listener of listeners) {
                    listener.apply(this, rest);
                }
            }
        }
        once(event: EVENT, listener: LISTENER) {
            const once_listener = function (this: EventifyBase, ...args: unknown[]) {
                this.removeListener(event, once_listener);
                listener.apply(this, args);
            };
            this.on(event, once_listener);
            return once_listener;
        }
        waitTimeout(event: EVENT, timeout: number) {
            this.waitfor(event, (reject) => {
                const timeoutId = setTimeout(() => {
                    reject();
                }, timeout);
                return () => {
                    clearTimeout(timeoutId);
                };
            });
        }
        waitfor<T extends (resolve: () => void, reject: () => void) => () => void>(event: EVENT, reject_callback: T) {
            return new Promise<void>((_resolve, _reject) => {
                let destroyCallbackCalled = false;
                const removeListener = () => {
                    this.removeListener(event, resolver);
                    this.removeListener(event, rejector);
                    if (destroyCallbackCalled) return;
                    destroyCallbackCalled = true;
                    destroyRejectCallback();
                };

                function resolver() {
                    _resolve();
                    removeListener();
                }
                function rejector() {
                    _reject();
                    removeListener();
                }
                const destroyRejectCallback = reject_callback(resolver, rejector);

                this.listenSelf(event, resolver);
                this.listenSelf(event, rejector);
            });
        }
        delegateTo<L extends LISTENER>(target: EventifyBase, ...rest: [...EVENT[], L]): L {
            if (arguments.length < 3) throw new Error('Eventify.delegateTo() need at least 3 arguments');
            const length = rest.length,
                listener = rest[length - 1] as L;
            for (let i = 0; length - 1 > i; i++) {
                const event = rest[i] as EVENT;
                if (typeof target.events[event] !== 'object') {
                    target.events[event] = [];
                }
                target.ev.push([this, event, listener]);
                this.on(event, listener);
            }
            return listener;
        }
        listenTo<E extends EventifyBase, L extends LISTENER>(
            target: E,
            ...rest: [...OmitLast<OmitFirst<Parameters<E['delegateTo']>>>, L]
        ): L {
            return target.delegateTo<L>(this, ...rest);
        }
        listenSelf(this: EventifyBase, ...rest: [...EVENT[], LISTENER]) {
            return this.listenTo(this, ...rest);
        }

        unlisten() {
            const before = this.ev.slice();
            for (let i = 0; before.length > i; i++) {
                const target = before[i][0];
                const eventName = before[i][1];
                const listener = before[i][2];
                target.removeListener(eventName, listener);
                const idx = this.ev.indexOf(before[i]);
                if (idx > -1) this.ev.splice(idx, 1);
            }
            if (this.ev.length > 0) {
                console.error('We have error in unlisten()', before, this.ev);
            }
        }
    };
}
export const Eventify = EventifyMixin(class {});

export type EventifyInstance = InstanceType<typeof Eventify>;
/* LIB : EVENTIFY ANY OBJECT */
export const eventify = function <T extends Object>(object: T) {
    const EVENTIFY = new Eventify();
    const props = [
        'on',
        'removeListener',
        'emit',
        'once',
        'listenTo',
        'delegateTo',
        'listenSelf',
        'unlisten',
        'ev',
        'events',
        'waitfor',
        'waitTimeout'
    ] as const;
    props.forEach((key) => {
        Object.defineProperty(object, key, { value: EVENTIFY[key], enumerable: false, writable: false });
    });
    return object as unknown as { [K in (typeof props)[number]]: EventifyInstance[K] };
};

type EventObjectEvents<T> = keyof T | '*' | 'before*' | `change:${Extract<keyof T, string>}` | `change:*`;
type EventObjectCallback<T, P = EventObjectEvents<T>> = (
    curr: T[Extract<keyof T, P>],
    prev: T[Extract<keyof T, P>]
) => void;
type EventObjectReturnType<T> = T &
    Omit<EventifyInstance, 'emit' | 'on' | 'once' | 'delegateTo' | 'removeListener' | 'listenSelf'> & {
        eventKeys: EventObjectEvents<T>;
        emit: (event: EventObjectEvents<T>, ...rest: unknown[]) => void;
        on: <L = EventObjectCallback<T>>(...rest: [EventObjectEvents<T>, ...Array<EventObjectEvents<T>>, L]) => L;
        once: <L = EventObjectCallback<T>>(event: EventObjectEvents<T>, listener: L) => L;
        listenSelf: <L = EventObjectCallback<T>>(...rest: [...Array<EventObjectEvents<T>>, L]) => L;
        removeListener: (arg1: EventObjectEvents<T>, arg2: LISTENER) => void;
        delegateTo: <L = EventObjectCallback<T>>(
            target: EventifyInstance,
            ...rest: [...Array<EventObjectEvents<T>>, L]
        ) => L;
    };

export function EventObject<T extends Record<E, V>, V, E extends EVENT>(object: T) {
    eventify(object);

    const eventObject = new Proxy(object, {
        set(target: EventObjectReturnType<T>, prop: E & symbol, val: T[E & symbol]) {
            const prev = object[prop];
            const curr = val;
            target.emit('before*', prop, val);
            // TODO doublecheck this one
            // @ts-ignore
            object[prop] = val;
            try {
                target.emit(prop as unknown as EventObjectEvents<T>, val, prev);
                if (prev !== curr) {
                    target.emit('change:*', prop, val);
                    target.emit(('change:' + String(prop)) as E, val, prev);
                }
                target.emit('*', prop, val, prev);
            } catch (message) {
                console.error(message);
            }
            return true;
        }
    });
    return eventObject as EventObjectReturnType<T>;
}

export function deferrify<T>(params?: { signal: AbortSignal }) {
    let resolve = null as unknown as (value: T | PromiseLike<T>) => void;
    let reject = null as unknown as (reason?: unknown) => void;

    const promise = new Promise<T>((resolveFunc, rejectFunc) => {
        resolve = resolveFunc;
        reject = rejectFunc;
        if (params?.signal.aborted) reject();
    });
    return { promise, resolve, reject };
}
