interface ButtonProps {
    children: React.ReactNode;
    greenButton?: boolean;
    secondary?: boolean;
    className?: string;
    onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({
    onClick = () => {},
    children,
    greenButton = false,
    secondary = false,
    className
}) => {
    const primaryClasses =
        ' px-6 py-2 text-[#83878e] border-[#545b62] hover:text-white/90 hover:bg-[#545b62] focus:outline-none  focus-visible:ring-offset-2';

    // TODO delete, I think this is the old one, but was not sure when merging
    // const secondaryClassess = 'px-4 bg-button-orange hover:bg-button-orange-dark border-button-orange-light text-white rounded-full';
    const secondaryClassess = 'px-4 bg-[#d07206] hover:bg-[#d07444] border-[#ffbd71] text-white rounded-full';

    return (
        <button
            type="button"
            className={
                `
                flex justify-center items-center 
                border 
                w-fit
                text-sm font-medium
                rounded-3xl
                transition duration-300 ease-in-out
            ` +
                className +
                (secondary ? secondaryClassess : primaryClasses) +
                (greenButton ? ' bg-green-600 hover:bg-green-700 text-white' : '')
            }
            onClick={onClick}
        >
            {children}
        </button>
    );
};
export default Button;
