import { IAutoplayConfig, ICalculatorConfig, ICurrencyConfig, IServerConfig } from '../../Shared/ConfigTypes';
import { IServerConfigMessage } from '../../Shared/MessageTypes';
import { ClientUrlParams } from './Types';
import { queryparams } from './utils/utils';

const query = queryparams<ClientUrlParams>();
export const config = new (class Config implements Omit<IServerConfigMessage, 'type'> {
    assetsLocation = 'assets/';
    textResolution = 1;
    bet: ICurrencyConfig = {
        code: query.currency || 'USD',
        default: 1000,
        step: 100,
        min: 1000,
        max: 100000,
        maxWin: 1000000,
        fractionDigits: 2,
        useGrouping: true,
        fastBets: [1, 2, 5, 10],
        precision: 3
    };

    autoplay: IAutoplayConfig = {
        minAutoCashOutMultiplier: 1.01,
        maxAutoCashOutMultiplier: 100,
        rounds: [10, 20, 50, 100],
        requiredStopConditions: true
    };

    serverConfig: IServerConfig = {
        ticksPerSecond: 1,
        tickDelayRate: 1.2
    };

    caclulator: ICalculatorConfig = {
        maxMult: 10000,
        // minMult: 0.96
        minMult: 1
    };

    multiplierColors = {
        0: 0xffffff,
        1: 0x34b4ff,
        2: 0x913ef8,
        20: 0xff59b4,
        100: 0xf79628,
        1000: 0xf7284b
    };
    historyMultiplierColors = {
        0: 0xc52222, // red
        2: 0x22c55e // green
    };
    query: ClientUrlParams;
    constructor() {
        this.query = queryparams<ClientUrlParams>();

        {
            // const url = new URL(location.href);
            // if (!this.query.token) {
            //     const token = uid();
            //     url.searchParams.set('token', token);
            // }
            // if (!this.query.balance) {
            //     const balance = 100009;
            //     url.searchParams.set('balance', String(balance));
            // }
            // history.replaceState(null, '', url);
        }

        this.query = queryparams<ClientUrlParams>();
    }
})();

export function getColorForMultiplier(multiplier: number): number {
    type Keys = keyof typeof config.multiplierColors;
    let k: Keys = 0;
    for (const key in config.multiplierColors) {
        if (<Keys>Number(key) > multiplier) break;
        k = <Keys>Number(key);
    }
    return config.multiplierColors[k];
}

export function getColorForHistoryMultiplier(multiplier: number): number {
    type Keys = keyof typeof config.historyMultiplierColors;
    let k: Keys = 0;
    for (const key in config.historyMultiplierColors) {
        if (<Keys>Number(key) > multiplier) break;
        k = <Keys>Number(key);
    }
    return config.historyMultiplierColors[k];
}
