import { Dialog } from '@headlessui/react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../other/Button';
import { DialogContext } from '../other/Dialog';

const ModalProvablyFair: React.FC<{}> = () => {
    const { close } = useContext(DialogContext);
    const { t } = useTranslation();

    return (
        <>
            <Dialog.Title as="h3" className="text-lg font-medium leading-6 modal-header">
                {t('modalProvably.title')}
            </Dialog.Title>

            <div className="relative grid gap-8 pt-3 grid-cols-1 p-2.5">{t('modalProvably.content')}</div>

            <div className="bottom-bar">
                <Button onClick={close}>{t('modalProvably.button')}</Button>
            </div>
        </>
    );
};

export default ModalProvablyFair;
