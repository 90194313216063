import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalProvablyFair from '../Modals/ModalProvablyFair';
import { DialogComponent } from '../other/Dialog';

export const Footer: React.FC<{}> = () => {
    const state = useState(false);
    const { t } = useTranslation();

    const [_isHelpOpen, setHelpOpen] = state;

    return (
        <>
            <DialogComponent state={state}>
                <ModalProvablyFair></ModalProvablyFair>
            </DialogComponent>
        </>
    );
};
