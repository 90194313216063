import { ICurrencyConfig } from './ConfigTypes';

export function checkBet(value: number, config: ICurrencyConfig): boolean {
    if (typeof value !== 'number' || isNaN(value)) return false;
    if (value < config.min) return false;
    if (value > config.max) return false;
    // todo
    // if (config.step) {
    //     const remainder = (value - config.min) % config.step;
    //     remainder !== 0 && parseFloat(remainder.toFixed(config.fractionDigits)) !== 0;
    // }
    return true;
}

export function roundPrecission(number: number, fraction: number) {
    if (fraction <= 0) {
        throw new Error('Fraction should be greater than 0');
    }
    const multiplier = Math.pow(10, fraction);
    const roundedNumber = Math.round(number * multiplier);

    return roundedNumber / multiplier;
}

export function range(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}

export function generateSeed(): string {
    return Array.from({ length: 5 }, () => Math.random().toString(36).substring(2, 6)).join('');
}

export function adjustAmountByCurrencyConfig(amount: number, config: { precision: number }): number {
    const currencyMultiplier = Math.pow(10, config.precision);

    return amount / currencyMultiplier;
}

export const adjustValueByPrecision = (value: number, config: { bet: { precision: number } }) => {
    return value / Math.pow(10, config.bet.precision);
};

export function uid() {
    for (let pos = 0xc4448, sizes = []; ; sizes.push(pos & 0xf), pos >>= 4) {
        if (!pos)
            return sizes
                .map((n) =>
                    Math.random()
                        .toString(16)
                        .substring(2, 2 + n)
                )
                .join('-');
    }
}
