export function vh(v: number, height: number): number {
    return (v * height) / 100;
}
export function vw(v: number, width: number): number {
    return (v * width) / 100;
}
export function vmin(v: number, width: number, height: number): number {
    return Math.min(vh(v, height), vw(v, width));
}
export function vmax(v: number, width: number, height: number): number {
    return Math.max(vh(v, height), vw(v, width));
}
export function gcd(width: number, height: number): number {
    return height == 0 ? width : gcd(height, width % height);
}
