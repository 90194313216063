import {
    IAtlasResources,
    IAudioResources,
    IBitmapFontResources,
    IFontResources,
    IImageResources,
    IMultiJsonResources,
    ISpineResources
} from './Models/AssetsModel';

export const ANIMATIONS: ISpineResources = {
    defaultPath: 'spine/',

    main: []
};

/** Should be in assets/spine/ */
export const MULTI_JSON_ASSETS: IMultiJsonResources = {
    defaultPath: 'spine/',
    main: []
};

/**
 * Should be in assets/audio/
 *
 * .MP3 or .WAV formats
 */
export const AUDIO: IAudioResources = {
    defaultPath: 'audio/',
    main: [
        {
            type: 'MUSIC',
            autoplay: false,
            loop: true,
            key: 'background',
            path: 'background.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'airplaneAudio',
            path: 'airplane.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'click',
            path: 'click.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'info',
            path: 'info.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'cashier',
            path: 'cashier.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'bet_click',
            path: 'bet_click.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'cash_out_click',
            path: 'cash_out_click.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: true,
            key: 'airplane_loop',
            path: 'airplane_loop.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'airplane_start',
            path: 'airplane_start.mp3'
        },
        {
            type: 'FX',
            autoplay: false,
            loop: false,
            key: 'airplane_end',
            path: 'airplane_end.mp3'
        }
    ]
};

export const IMAGES: IImageResources = {
    defaultPath: 'sprites/',

    main: [
        {
            key: 'poweredby',
            path: 'poweredby.png'
        },
        {
            key: '1',
            path: 'avatars/avatar_01.png'
        },
        {
            key: '2',
            path: 'avatars/avatar_02.png'
        },
        {
            key: '3',
            path: 'avatars/avatar_03.png'
        },
        {
            key: '4',
            path: 'avatars/avatar_04.png'
        },
        {
            key: '5',
            path: 'avatars/avatar_05.png'
        },
        {
            key: '6',
            path: 'avatars/avatar_06.png'
        },
        {
            key: '7',
            path: 'avatars/avatar_07.png'
        },
        {
            key: '8',
            path: 'avatars/avatar_08.png'
        },
        {
            key: '9',
            path: 'avatars/avatar_09.png'
        },
        {
            key: '10',
            path: 'avatars/avatar_10.png'
        },
        {
            key: '11',
            path: 'avatars/avatar_11.png'
        },
        {
            key: '12',
            path: 'avatars/avatar_12.png'
        },
        {
            key: '13',
            path: 'avatars/avatar_13.png'
        },
        {
            key: '14',
            path: 'avatars/avatar_14.png'
        },
        {
            key: '15',
            path: 'avatars/avatar_15.png'
        },
        {
            key: '16',
            path: 'avatars/avatar_16.png'
        },
        {
            key: '17',
            path: 'avatars/avatar_17.png'
        },
        {
            key: '18',
            path: 'avatars/avatar_18.png'
        },
        {
            key: '19',
            path: 'avatars/avatar_19.png'
        },
        {
            key: '20',
            path: 'avatars/avatar_20.png'
        },
        {
            key: '21',
            path: 'avatars/avatar_21.png'
        },
        {
            key: '22',
            path: 'avatars/avatar_22.png'
        },
        {
            key: '23',
            path: 'avatars/avatar_23.png'
        },
        {
            key: '24',
            path: 'avatars/avatar_24.png'
        },
        {
            key: '25',
            path: 'avatars/avatar_25.png'
        },
        {
            key: '26',
            path: 'avatars/avatar_26.png'
        },
        {
            key: '27',
            path: 'avatars/avatar_27.png'
        },
        {
            key: '28',
            path: 'avatars/avatar_28.png'
        },
        {
            key: '29',
            path: 'avatars/avatar_29.png'
        },
        {
            key: '30',
            path: 'avatars/avatar_30.png'
        },
        {
            key: '31',
            path: 'avatars/avatar_31.png'
        },
        {
            key: '32',
            path: 'avatars/avatar_32.png'
        },
        {
            key: '33',
            path: 'avatars/avatar_33.png'
        },
        {
            key: '34',
            path: 'avatars/avatar_34.png'
        },
        {
            key: 'AmigatorPropeller',
            path: 'amigator_propeller.png'
        },
    ]
};

export const SPRITESHEETS: IImageResources = {
    defaultPath: 'spritesheets/',

    main: [
        {
            key: 'airplaneAnimation',
            path: 'plane.json'
        },
        {
            key: 'airplaneMain',
            path: 'airplane_main.json'
        },
        {
            key: 'airplanePropeller',
            path: 'airplane_propeller.json'
        },
    ]
};

/** Should be in assets/atlases/ */
export const ATLASES: IAtlasResources = {
    defaultPath: 'atlases/',

    main: []
};

/** Should be in assets/bitmapfonts/ */
export const BITMAP_FONTS: IBitmapFontResources = {
    defaultPath: 'bitmapfonts/',

    main: []
};

/** Should be in assets/fonts/ */
export const FONTS: IFontResources = {
    defaultPath: 'fonts/',

    main: [
        {
            key: 'Roboto-Bold',
            path: 'Roboto-Bold.ttf'
        },
        {
            key: 'Roboto-Regular',
            path: 'Roboto-Regular.ttf'
        }
    ]
};
