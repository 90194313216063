import { HTMLAttributes } from 'react';

const defaultStyles =
    'flex flex-shrink-0 flex-col items-center justify-center rounded-full bg-slate-300 text-cyan-600 aspect-square w-6 overflow-hidden';

// TODO we will use user thumbnails later
export default function Avatar(props: { 'data-avatarid': string } & HTMLAttributes<HTMLDivElement>) {
    const numbersOnly = props['data-avatarid'].replace(/\D/g, '');
    const id = Number(numbersOnly === '0' ? 1 : numbersOnly) % 35;

    return (
        <div {...props} className={defaultStyles + ' ' + (props.className || '')}>
            <div className={`flex w-full avatar${id < 10 ? '0' : ''}${id}`}></div>
        </div>
    );
}
